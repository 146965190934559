@import 'variables';

// border radius
@mixin border-radius {
  border-radius: $b-radius-10;
}

@mixin border-radius-sm {
  border-radius: $b-radius-5;
}

@mixin border-radius-top {
  border-top-left-radius: $b-radius-10;
  border-top-right-radius: $b-radius-10;
}

@mixin border-radius-bottom {
  border-bottom-left-radius: $b-radius-10;
  border-bottom-right-radius: $b-radius-10;
}

@mixin border-radius-left {
  border-top-left-radius: $b-radius-10;
  border-bottom-left-radius: $b-radius-10;
}

@mixin border-radius-right {
  border-top-right-radius: $b-radius-10;
  border-bottom-right-radius: $b-radius-10;
}

@mixin border-radius-top-sm {
  border-top-left-radius: $b-radius-5;
  border-top-right-radius: $b-radius-5;
}

@mixin border-radius-bottom-sm {
  border-bottom-left-radius: $b-radius-5;
  border-bottom-right-radius: $b-radius-5;
}

@mixin border-radius-left-sm {
  border-top-left-radius: $b-radius-5;
  border-bottom-left-radius: $b-radius-5;
}

@mixin border-radius-right-sm {
  border-top-right-radius: $b-radius-5;
  border-bottom-right-radius: $b-radius-5;
}

// flex
@mixin flex-column($flex: 1, $overflow: auto) {
  display: flex;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  flex-flow: column;
  flex: $flex 0 auto;
  overflow: $overflow;
}

@mixin flex-row($flex: 1) {
  display: flex;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  flex-flow: row;
  flex: $flex 0 auto;
}

@mixin flex-center() {
  justify-content: center;
  align-items: center;
}

@mixin flex-1() {
  flex: 1 0 auto;
}

@mixin font-bold() {
  font-weight: 700;
}

@mixin font-semibold() {
  font-weight: 600;
}

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin pointer() {
  cursor: pointer;
}

@mixin btn-padding() {
  padding: 0.5rem 1.25rem;
}

@mixin btn-font-size() {
  font-size: 13px;
}
