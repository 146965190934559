@import 'libs/ui-template/src/lib/styles/scss/variables';

$custom-secondary-color: #ffcc38;
$hover-color: #ffd984;
$menu-hover-color: #fbbb0033;
$dark-color: #393939;
$white-shade-color: #f2f2f2;
$gray-color: #999999;
$border-color: #e9e9e9;
$warn-color: #d74747;
$bg-gray: #f2f2f2;

$b-radius-5: 5px;
$b-radius-10: 10px;

$general-padding: 30px;
$general-margin: 30px;

$general-padding-sm: 15px;
$general-margin-sm: 15px;
