@import 'ui-template';
@import 'mixins';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@font-face {
  font-family: 'PTSerif';
  src: url('assets/font/PTSerif-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PTSerif';
  src: url('assets/font/PTSerif-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('assets/font/Merriweather-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('assets/font/Merriweather-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

html {
  * {
    font-family: 'Inter', sans-serif;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  font-size: 14px;
}

body,
.table th,
.text-wrap table th {
  color: $dark-color;
}

.table th,
.table > :not(:first-child),
.table thead th {
  border: 0;
}

.table > thead > tr > th {
  text-transform: none;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

ngx-spinner {
  font-size: 2rem;
  color: #fff !important;

  div {
    &.black-overlay div div,
    &.loading-text {
      font-size: 2rem;
      color: #fff !important;
    }
  }
}

.main-container {
  max-width: 1200px !important;
}

.container-sm {
  position: relative;
  margin-top: calc(2 * $general-margin);
  margin-bottom: calc(2 * $general-margin);

  a.back-button {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    font-size: 15px;
    font-weight: 300;
    position: absolute;
    top: -9%;
    left: 1.5%;

    img {
      width: 2rem;
      margin-right: 0.5rem;
    }

    @media (min-width: 992px) {
      left: -35%;
      top: -0.75%;
    }
  }
}

.support-header {
  max-width: 700px;
}

.card-deck .card {
  min-width: 220px;
}

.button-dark {
  cursor: pointer;
  text-align: center;
  border: none;
  background-color: #454a53;
  color: #ffffff !important;
  border-radius: 2rem;
  padding: 1rem;
  min-width: 13rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(133, 133, 133, 0.78);
  -moz-box-shadow: 0px 0px 10px 1px rgba(133, 133, 133, 0.78);
  box-shadow: 0px 0px 10px 1px rgba(133, 133, 133, 0.78);
}

.button-white {
  color: #454a53 !important;
  border: 1px solid #454a53;
  background: #ffffff;
  cursor: pointer;
  text-align: center;
  border-radius: 2rem;
  padding: 1rem;
  min-width: 13rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(133, 133, 133, 0.78);
  -moz-box-shadow: 0px 0px 10px 1px rgba(133, 133, 133, 0.78);
  box-shadow: 0px 0px 10px 1px rgba(133, 133, 133, 0.78);
}

.button-small {
  padding: 0.8rem 1.5rem;
}

.button-white:focus {
  box-shadow: 0 0 4pt 3pt #6b96e7;
  outline: 0;
}

.button-dark {
  &:focus {
    box-shadow: 0 0 4pt 3pt #6b96e7;
    outline: 0;
  }

  &:disabled {
    background-color: #afafaf;
  }

  .fa {
    color: #ffffff !important;
  }
}

.jumbotron-headline {
  padding: 2rem !important;
}

.main-logo {
  height: 35px;
}

ngb-alert p,
mat-dialog-actions.mat-dialog-actions {
  margin-bottom: 0;
}

.underline {
  border-bottom: 3px solid #6c757d;
}

.btn-dark {
  background-color: $dark-color;
  border-color: $dark-color;
  color: #fff !important;

  &:hover {
    opacity: 0.8;
  }
}

.btn-outline-dark {
  color: $dark-color;
  border-radius: $b-radius-5;
  font-size: 14px;

  &:hover {
    background-color: $dark-color;
  }
}

.btn-secondary,
.btn-secondary:hover {
  background-color: $custom-secondary-color;
  border-color: $custom-secondary-color !important;
  color: $dark-color !important;
  box-shadow: none;

  &:disabled {
    background-color: $custom-secondary-color;
    border-color: $custom-secondary-color !important;
    color: $dark-color !important;
  }
}

.btn-secondary:hover {
  opacity: 0.9;
}

.badge {
  border-radius: $b-radius-5;
  background-color: $gray-color;
  display: inline;
}

/*
 * Typography:
 */

.display-5 {
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: 300;
}

/*
 * Dialog (popup) changes:
 */
.modal {
  z-index: 1055;
}

.modal-header {
  border-bottom: 0;
  padding: $general-padding $general-padding 0;

  .modal-title {
    @include font-bold();
    font-size: 18px;
  }
}

.modal-body {
  padding: $general-padding $general-padding $general-padding-sm;

  ng-select {
    width: 100%;
    min-width: 300px;
  }

  .content-img {
    border: 2px solid #464c54;
    border-radius: 5px;
    max-width: 33%;
    float: right;
    margin-left: 0.25rem;
  }

  .ng-select .ng-select-container {
    border: 1px solid rgba($dark-color, 0.3);
    border-radius: $b-radius-5;
    min-height: 48px;

    .ng-value-container {
      display: flex;
      align-items: center;
      padding-left: 13px !important;
    }

    .ng-value-container .ng-placeholder {
      color: $dark-color;
      opacity: 0.5;
      padding-bottom: 0;
      padding-top: 6px;
      font-size: 15px;
    }

    .ng-arrow-wrapper {
      width: 40px;
    }

    .ng-arrow-wrapper .ng-arrow {
      border: 0;
      background: url('assets/img/sort-down-solid.svg') no-repeat center center;
      background-size: contain;
      width: 0.7rem;
      height: 0.6rem;
    }
  }

  ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    transform: rotate(180deg);
  }

  .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: $custom-secondary-color;
    box-shadow: none;
  }
}

.modal-footer {
  border-top: 0;
  padding: 0 $general-padding $general-padding;

  .btn {
    @include btn-font-size();
    @include btn-padding();
  }
}

img.close-modal-img {
  height: 1.3rem !important;
}

.cdk-overlay-pane {
  position: absolute !important;
  top: 20px;
}

/**
 * LICENSES
 * ... locking/hiding the elements
 */

button.no-license,
a.no-license {
  @extend .disabled;
}

/*
 * GENERAL
 */

.link-arrow {
  cursor: pointer !important;
}

.text-danger {
  color: #721c24 !important;
}

.rotate180 {
  transform: rotate(180deg);
}

.opacity {
  opacity: 0.7;
}

/*
 * Pagination
 */

.page-item.active .page-link {
  background-color: $custom-secondary-color;
  border-color: $custom-secondary-color;
  color: $dark-color;
}

.page-item.active .page-link:hover {
  color: $dark-color !important;
}

.form-select,
.form-select:focus {
  border-color: $dark-color;
  background-image: url('assets/img/dropDown.svg');
  background-size: 0.6rem;
  font-size: 13px;
}

.form-control {
  border-radius: $b-radius-5;

  &:focus {
    border-color: $custom-secondary-color;
    box-shadow: none;
  }
}

.form-control-lg {
  font-size: 15px;
}

/*
 * todo(doubravskytomas): refactor:
 */

.isophi-shadow-box {
  background-color: white;
  margin: 0 0 20px;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 5px -2px rgba(150, 150, 150, 1);
}

.clear-both {
  clear: both;
}

@media (min-width: 992px) {
  .app-content {
    margin-left: 0;
  }
}

.app-content {
  margin-top: 65px !important;
}

/*
 * Printable report
 */
@media print {
  app-root > app-header,
  app-root > app-footer {
    display: none;
  }
}

.nav {
  gap: 15px;

  &.nav-tabs {
    border-bottom: 0;

    .nav-link {
      @include border-radius();
      height: 2.3125rem;
      border: 0;
      color: $dark-color;

      &:hover:not(.disabled) {
        color: $dark-color;
      }

      &.active {
        background-color: $hover-color;
        color: $black;
      }
    }
  }
}
